import React from 'react'
import Button from './buttons/Button'
import './ui.css'

const Input = ({ placeHolder='escriba aqui',onClick, height='24px', type='text', onChange, name, formElement, error=false}) => {
  return (
    <div className='input-title'>
        <input
        required
        error={error}
        name={name} 
        value={formElement}
        onChange={onChange}
        id="outlined-required"
        placeholder={placeHolder}
      />
      <Button onClick={onClick} type="submit" text='Unirme' width='125px' colorBorder='#1B293F' height='48px' borderRadius='0px 8px 8px 0px' colorbg='#1B293F' padding={'4px 8px'}/>
    </div> 
  )
}

export default Input
