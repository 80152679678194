import './NewsDetails.css';
import {  useEffect, useState } from 'react';
import { News } from '../news/News';
import { fetchSinToken } from '../../components/helper/fetchSinToken';
import { useNavigate, useParams } from 'react-router-dom';

export const NewsDetails = () => {
  const {id} = useParams();
  const [postData, setPostData] = useState({})
  const navigate = useNavigate()
  
  useEffect(() => {
      fetchSinToken(`news/${id}`).then((response) => response.json())
    .then((data) => {
     if( data === null){
      navigate('/')
     }
      setPostData(data)
    }) 
  }, [id, navigate]);
  
  function replaceWithBr(text) {
    return text.replace(/\n/g, "<br />")
  }

  return (
    <div className='container-news-detailes' id=''>
      {
        postData._id ?
        <div className='news-details-container'>
          <h2>{postData.title}</h2>
          <div>
            <img src={postData.imgUrl} alt=""></img>
            <div className='text' dangerouslySetInnerHTML={{__html: replaceWithBr(postData.text)}}></div>
          </div>
          </div>
          :null
      }
      <News id={postData._id}/>
    </div>
  )
}