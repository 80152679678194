import React from 'react';
import { Card } from "../../components/news/Card";
import { Context } from '../../store/appContext';
import { useContext } from 'react';
import './News.css'
import { useForm } from '../../components/hooks/useForm';
import Input from '../../components/ui/Input';
import { FooterCard } from '../../components/ui/footerCard/FooterCard';
import image from '../../img/img-newsletter.svg'
import { Box, Skeleton } from '@mui/material';
import { customAlert } from '../../components/helper/alert';

export const News = ({id=''}) => {
    const { store } = useContext(Context);

  const [value, handleInputChange, reset] = useForm({email:''})

  const handleSubmit = (e) => {
    let regexEmail = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
    if (!regexEmail.test(value.email)) {
        customAlert('Error', 'Correo es obligatorio y debe ser válido.', 'error');
    }else{
        console.log('disabled')
        e.preventDefault()
    
        fetch(`https://hook.us1.make.com/f1fj66z2wcn9qathgok3gi6pnsdx7qdh?email=${value.email}`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
          },
        }).then(()=>{
            customAlert(' 🎉Te damos la bienvenida🎉', 'Estamos muy felices de tenerte con nosotros.', 'success');
            reset()
        }) 
      }
  }
    let newsCard =  [];
    const newsData = store.news;
   
    // eslint-disable-next-line array-callback-return
    newsData.map(news => {
        if(id.length < 2){
            newsCard.unshift(news)
        }else{
            if(news._id !== id && newsCard.length < 2 ){
                newsCard.unshift(news)
            } 
        }
    });

    return (
        <div className='news-container' id='news'>
            <h2>Últimos Artículos</h2>
             { newsData.length > 0 ?
                <div >
                    <div className='news-listCards'>
                        {newsCard.map(({ _id, type, title, author, text, imgUrl }) => <Card key={_id} {...{ _id, type, title, author, text, imgUrl }} />)}
                    </div>
                </div> :
                <Box sx={{ width:'100%', maxWidth:'1258px'}}>
                    <Box sx={{display:'flex', gap:'8px', width:'100%', marginBottom:'20px'}}>
                        <Skeleton sx={{ bgcolor: '#E1E3FF' }} variant="rounded" animation="wave" width='20%' height={192} />
                        <Skeleton sx={{ bgcolor: '#E1E3FF' }} variant="rounded" animation="wave" width='80%' height={192} />
                    </Box>
                    <Box sx={{display:'flex', gap:'8px', width:'100%', marginBottom:'20px'}}>
                        <Skeleton sx={{ bgcolor: '#E1E3FF' }} variant="rounded" animation="wave" width='20%' height={192} />
                        <Skeleton sx={{ bgcolor: '#E1E3FF' }} variant="rounded" animation="wave" width='80%' height={192} />
                    </Box>
                </Box>
            }

            <FooterCard title={'BLOG'} image={image}
                    htmlElement={
                        <form>
                        <Input  name='email' onClick={handleSubmit} formElement={value.email} onChange={handleInputChange} />
                        </form>
                    } >
                        <p>Únete a nuestra comunidad educativa. Entérate de las últimas <strong>tendencias en enseñanza y tecnología</strong> con nuestro contenido actualizado.</p>
            </FooterCard>
        </div>
    )
}
