import React from 'react';
import './socios.css'

import { Membrete } from '../../components/dividirSeccion/Membrete'


export const Socios = () => {

  return (
        <div className='socios__section'>
            <Membrete title=' Nos apoyan' top='0' width='50vw'/>
            <div className="socios__images-container">
                {
                  empresas.map((empresa, index)=> 
                    <div key={index} className='socios__card'>
                      <a href={empresa.url} target="_blank" rel="noreferrer">
                        <img src={empresa.nombre} alt="" />
                      </a>
                    </div>
                  )  
                }    
            </div>
        </div>
  )
}

export default Socios

const empresas = [
    {nombre:'img/socio-witi.png', url: 'https://witi.cl/'},
    {nombre:'img/socio-corfo.png', url: 'https://www.corfo.cl/sites/cpp/homecorfo'},
    {nombre:'img/socio-microsof.png', url: 'https://www.microsoft.com/es-ve'},
    {nombre:'img/socio-digevo.png', url: 'https://www.digevoventures.com/'},
    {nombre:'img/socio-chilemass.png', url: 'https://www.chilemass.org/'},
    {nombre:'img/socio-ei3.png', url: 'https://3ie.usm.cl/'},
    {nombre:'img/socio-dokuma.png', url: 'https://dokuma.tech/'},
    {nombre:'img/socio-inn-work.png', url: 'https://www.innwork.cl/'},
]