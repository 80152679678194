import React from 'react'

 const Button = ({children,  text, onClick, colorbg='#5E67FF', colorText='#FFFF', border='1', colorBorder='#FFFF', width='100%', href='', height='48px', borderRadius, fontSize='20px', padding, type}) => {
  
  return (

    <>
    {
      href.length > 0 ?
      <a href={href}>
        <button  onClick={onClick} type={type} style={{padding, fontSize, color:colorText, background:colorbg, border:`${border}px solid ${colorBorder}`, width, height, borderRadius }} className='btn-call-to-action'>
          {children} {text}
        </button>
      </a> :
      <button  onClick={onClick} type={type} style={{padding, fontSize, color:colorText, background:colorbg, border:`${border}px solid ${colorBorder}`, width, height, borderRadius }} className='btn-call-to-action'>
        {children} {text}
      </button>
    }
    </>
  )
}

export default Button