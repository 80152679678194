import React, { useState } from 'react';
import './style.css'
import logoLudens from '../../img/ludens-logo-purple.svg'
import Button from '../ui/buttons/Button'
import SidebarNav from '../ui/sidebar-nav/SidebarNav';
import { Link } from 'react-router-dom';


export const Menu = () => {

  const [count, setCount] = useState(false);

  return (
    <header>
      <div className="header_container">
       <img className='logo-ludens' src={logoLudens} alt='profesora de ludens'/>
         <ul className='links-menu'>
           <li>
             <a href='/'>
              Inicio
             </a>
            </li>
          <li>
            <Link to={`/news`}>
             Blog
            </Link>
          </li>
          {/*  <li>
              <a href='/recursos'>
                Descargas
              </a>
           </li> */}
         </ul>
      <div style={{display:'flex', gap:'16px'}}> 
        <Button fontSize='13px' borderRadius={'4px'} height='30px'  colorbg='#E1E3FF' text='Acceder' colorText='#5B66FE' colorBorder='#E1E3FF' width='92px' href='https://app.ludenseducacion.cl/autenticacion/login'/>
        <Button fontSize='13px' borderRadius={'4px'} height='30px' colorbg='#5B66FE' text='Contacta con una agente de Ventas' colorText='white' colorBorder='#5B66FE' width='110%' href='https://wa.me/+56948421272'/>
        <button className='burguer_button' onClick={() => setCount(!count)}></button>
      </div>
      </div>
      
       <SidebarNav  visible={count} /> 

    </header>
  )
}