import React from 'react'
import { Membrete } from '../../components/dividirSeccion/Membrete'
import './styles.css'


export const HowWeDo = () => {
  return (
    <div className='howWeDo-container' id='howwedo'>
     <Membrete title='Cómo funciona' width='65vw'>
        <p>
        Transforma tus procesos de evaluación con nuestro asistente inteligente. <br/> <strong>Más de 100.000 preguntas</strong> que te permitirán construir todas las evaluaciones que necesites.  
        </p>
     </Membrete>
      <iframe className='howWeDo-container__video' width="560" height="315" src="https://www.youtube.com/embed/N1kPdxYcV3w" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
     {/*  <div className='howWeDo-listCards'>
      {info.map(({text, title, src, orden}, index) => <Card key={index} {...{text, title, src, orden}}/>)}
      </div> */}
    </div>
  )
}

/* const info = [
  {
  title:'A solo 1 minuto de la evaluación perfecta',
  src:asigncacionCursos,
  text:'Escoge el curso al que quieres evaluar, selecciona la unidad y objetivos de aprendizaje.',
  orden:0
},
{
  title:'El control de la evaluación es tuyo',
  src:control,
  text:`Nuestro sistema de recomendación inteligente crea evaluaciones basadas en las necesidades de tus estudiantes. No obstante, podrás modificar y adaptar todos los elementos que quieras.`,
  orden:1
},
{
  title:'No más fines de semana, ni noches de corrección',
  src:correccion,
  text:'Corrige y registra los resultados de tus estudiantes inmediatamente, independientemente del formato de la evaluación.',
  orden:0
},
{
  title:'En un solo lugar',
  src:tabla,
  text:`Realiza la conversión de notas y conoce el desempeño de cada estudiante.`,
  orden:1
},
{
  title:'Ayuda a cada uno de tus alumnos',
  src:dashboard,
  text:'Obtendrás métricas para entregar la retroalimentación que tu curso necesita.',
  orden:0
}
] */