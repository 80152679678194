import React from 'react'
import { Info } from '../../components/hero/Info'
import './style.css'
import heroImg from '../../img/hero-image.svg'


export const Hero = () => {
  return (
    <div className='hero-wrapper'>
      <Info/>
      <img src={heroImg}   className='hero-img' alt='profesora de ludens' />
    </div>
  )
}
