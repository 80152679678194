import React from "react";
import { Routes, Route } from 'react-router-dom';
import { Dashboard } from "./views/dashboard/Dashboard";
import { Menu } from "./components/menu/Menu";
import { Footer } from "./views/footer/Footer";
import { NewsPage } from "./components/newsPage/NewsPage"
import injectContext from "./store/appContext";
import { News } from "./views/news/News";
import { NewNews } from "./components/newNews/NewNews";
import { Recursos } from "./views/recursos/Recursos";


const Layout = () => {
	return (
		<React.Fragment>
        <Menu/>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/news/:id" element={<NewsPage />} />
          <Route path="/news" element={<News />} />
          <Route path="/new-news" element={<NewNews />} />
          <Route path="/recursos" element={<Recursos />} />
        </Routes>
          <Footer />
      

    </React.Fragment>
	);
};

export default injectContext(Layout);