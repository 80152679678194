import React from 'react'
import { Membrete } from '../../components/dividirSeccion/Membrete'
import tostadora from '../../img/tostadora.svg'
import './styles.css'

/* const img = require.context('../../img', true);
const file = require.context('../../downloads', true); */

export const Recursos = () => {
  return (
    <div className='recursos-container' id='recursos'>
     <Membrete fontSize={'40px'} title='¡Oops! El material descargable se
      sigue preparando.' width='800px'>
       {/*  <p style={{marginTop: 10}}>
        Completa nuestro formulario y accede gratis a nuestros recursos para mejorar tus clases y motivar a tus estudiantes.
        </p> */}
     </Membrete>
      <div className='recursos-listCards'>
        <img src={tostadora} alt='imagen de evaluaciones creandose'/>
     {/*  {topics.map(({title, src, file, text}) => <Card key={title} {...{title, src, file, text}}/>)} */}
      </div>
    </div>
  )
}

/* const topics = [
  {
    title: 'Historia 8º Básico',
    src: img(`./historia8vo.png`),
    file: file(`./Prueba Diagnóstica Matemática 5° Básico.pdf`),
    text:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
  },

  {
    title: 'Lenguaje 5º Básico',
    src: img(`./lenguaje5to.png`),
    file: file(`./Prueba Diagnóstica Matemática 5° Básico.pdf`),
    text:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
  },

  {
    title: 'Matemáticas 8º Básico',
    src: img(`./matematicas8vo.png`),
    file: file(`./Prueba Diagnóstica Matemática 5° Básico.pdf`),
    text:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
  },

  {
    title: 'Matemáticas 5º Básico',
    src: img(`./matematicas5to.png`),
    file: file(`./Prueba Diagnóstica Matemática 5° Básico.pdf`),
    text:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
  },

  {
    title: 'Inglés 8º Básico',
    src: img(`./ingles8vo.png`),
    file: file(`./Prueba Diagnóstica Matemática 5° Básico.pdf`),
    text:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
  },

  {
    title: 'Ciencias 8º Básico',
    src: img(`./ciencias8vo.png`),
    file: file(`./Prueba Diagnósticaciencias 8° Básico.pdf`),
    text:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
  },

] */