import React from 'react';
import {Link} from 'react-router-dom';
import Button from '../ui/buttons/Button';

function Card({ _id, type, title, author, text, imgUrl }) {

  const onButtonClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function replaceWithBr(text) {
    return text.replace(/\n/g, "<br />")
  }

    return (
      <div>
          <li className="news-card-container">
              <img src={imgUrl} alt=""></img>
              <div className="news-card-info">
                  <Link to={`/news/${_id}`}>
                   <h2 onClick={onButtonClick} >{title}</h2>
                  </Link>
                  <div dangerouslySetInnerHTML={{__html: replaceWithBr(text)}}></div>
                  <Link to={`/news/${_id}`} className="news-card-container_button">
                  <Button borderRadius='4px' fontSize='16px'  onClick={onButtonClick} text={'Leer más'} width={'100px'} colorbg='linear-gradient(90deg, #5E67FF 0%, #4CA1FF 100%)' height={'28px'}/>
                  </Link>
              </div>
          </li>
          <li className="news-card-container2">
              <Link to={`/news/${_id}`}>
                   <h2 onClick={onButtonClick} >{title}</h2>
              </Link>
              <div className="news-card-info2">
              <img src={imgUrl} alt=""/>
              <div dangerouslySetInnerHTML={{__html: replaceWithBr(text)}}></div>
              </div>
                <Link to={`/news/${_id}`} className="news-card-container_button">
                   <Button borderRadius='4px' onClick={onButtonClick} fontSize='16px' text={'Leer más'} width={'82px'} colorbg='linear-gradient(90deg, #5E67FF 0%, #4CA1FF 100%)' height={'28px'}/>
                </Link>
          </li>
      </div>
    );

}

export { Card };