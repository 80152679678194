import React, { useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from "../ui/buttons/Button";
import { useForm } from '../hooks/useForm';
import { customAlert } from '../helper/alert';
import { fetchSinToken } from '../helper/fetchSinToken';
import "./NewNews.css";

function NewNews() {

    const initialForm = {
        title: "",
        author: "",
        text: "",
    }

    const [formValues, handleInputChange, reset] = useForm(initialForm);
    const { title, author, text } = formValues;


    const [imageSelected, setImageSelected] = useState("");
    const uploadImage = async () => {

        const file = imageSelected;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "kidvynnq");

        fetch(
            "https://api.cloudinary.com/v1_1/dtbryjcn5/image/upload",
            {
                method: "POST",
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setImageSelected("");
                saveNews(data.secure_url);
            })
            .catch((error) => {
                console.error(error);
                customAlert('Error', 'No se pudo cargar la imagen.', 'error');
                return false;
            })

    };


    const saveNews = async (imgUrl) => {
        formValues.imgUrl = imgUrl;
        try {
            const response = await fetchSinToken('news', formValues, 'POST');
            const data = await response.json();
            console.log(data);

            customAlert('¡Listo!', 'Noticia creada', 'success');
            reset();

        } catch (error) {
            console.warn(error);
            customAlert('Error', 'Ha ocurrido un error con la creación de la noticia.', 'error');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        var allowedExtensions = /(.jpg|.jpeg|.png|.gif)$/i;

        if (formValues.title.length < 5) {
            customAlert('Error', 'Título es obligatorio y debe tener como mínimo 5 carácteres.', 'error');
        } else if (formValues.author.length < 5) {
            customAlert('Error', 'Autor es obligatorio y debe tener como mínimo 5 carácteres.', 'error');
        } else if (formValues.text.length < 50) {
            customAlert('Error', 'Texto es obligatorio y debe contener más de 50 carácteres.', 'error');
        } else if (!imageSelected) {
            customAlert('Error', 'Imagen es obligatoria.', 'error');
        } else if (!allowedExtensions.exec(imageSelected.name)) {
            customAlert('Error', 'Por favor seleccione un archivo de tipo imagen. Formatos permitidos: .jpg, .jpeg, .png, .gif.', 'error');
        } else {
            uploadImage();
        }
    }

    return (
        <div className='new-news-form'>
            <p style={{ fontSize: 24, marginBottom: 30 }}>Crear noticia</p>

            <form className='inputs'>
                <TextField
                    required
                    name="title"
                    onChange={handleInputChange}
                    value={title}
                    label="Título"
                    type={"text"}
                />

                <TextField
                    required
                    name="author"
                    onChange={handleInputChange}
                    value={author}
                    label="Autor"
                    type={"email"}
                />

                <TextField
                    required
                    name="text"
                    onChange={handleInputChange}
                    value={text}
                    label="Texto"
                    type={"text"}
                    multiline
                    rows={10}
                    maxRows={Infinity}
                />

                <TextField
                    required
                    type={"file"}
                    onChange={(event) => {
                        setImageSelected(event.target.files[0]);
                    }}
                />
                <div className="buttons-form">
                    <Button onClick={handleSubmit} text="Crear"></Button>
                </div>
            </form>

        </div >
    );

}

export { NewNews };