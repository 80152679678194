export const fetchSinToken = async(endpoint, data, method = 'GET', base=process.env.REACT_APP_API) => {

    const url = `${base}/${endpoint}`

    if (method === 'GET') {
        return fetch(url)
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
    }

}