import React from "react";
import { Hero } from '../hero/Hero';
import { HowWeDo } from '../howWeDo/HowWeDo'
import Socios from '../../components/socios/socios';
import { FooterCard } from "../../components/ui/footerCard/FooterCard";
import image from "../../img/card-footer-img.svg"
import Button from "../../components/ui/buttons/Button";


export function Dashboard() {

    return (
        <div style={{display:'flex', flexDirection:'column', alignItems:'center', gap:'168px'}}>
            <Hero />
            <HowWeDo />
            <Socios />
           {/*  <WorkWithUs
                boton='Únete a nuestro equipo'
            /> */}
            <FooterCard 
             image={image} 
             title={'¡Inscribete en nuestro taller: "Herramientas de Inteligencia Artificial para una educación personalizada"'} 
             text1='¡No esperes más y haz un cambio efectivo en tu colegio desde hoy mismo! Cupos limitados' 
             htmlElement={ 
             <Button
                text='Inscribete' 
                colorBorder='#1B293F' 
                colorText='white' 
                colorbg='#1B293F'
                width='206px' 
                href='https://2l19u9coi6w.typeform.com/s/ludensetm' />}
             >    
             <p style={{color:'white', fontWeight:'900', fontSize:'24px'}}>¡Apresúrate, solo quedan pocos cupos!</p>  
            </FooterCard>
        </div>
    )
}