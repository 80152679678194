import React from 'react';
import { NewsDetails } from '../../views/newsDetails/NewsDetails';

export function NewsPage() {
   
    return (
        <React.Fragment>
            <NewsDetails/>
        </React.Fragment>
    )
} 