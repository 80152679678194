import React from 'react'
import Button from '../ui/buttons/Button'

export const Info = () => {
  return (
    <div className='wrap-hero-text'>
      <h1 className='purple-color'>Ludens</h1>
      <h2>
      1ª plataforma educativa asistida<span className='purple-color'> Inteligencia Artificial</span> para la creación de evaluaciones personalizadas.
      </h2>
      <p>Crea evaluaciones en menos de 1 minuto y optimiza tu tiempo para impulsar el potencial de tus estudiantes. <br/><br/>Identifica las necesidades educativas de cada uno mediante informes personalizados.</p>
      <Button 
      text='Agenda tu demo' 
      width='191px' 
      colorbg='linear-gradient(90deg, #5E67FF 0%, #4CA1FF 100%)'
      href='https://calendly.com/ludens-chile/demo-personalizada?month=2023-08'/>
    </div>
  )
}
