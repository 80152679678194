import React from 'react'
import './Sidebar_nav.css'
import home from '../../../img/mobile-home.svg'
import linkedin from '../../../img/mobile-linkedin.svg'
import instagram from '../../../img/mobile-instagram.svg'
import news from '../../../img/mobile-news.svg'
import { Link } from 'react-router-dom'
import Button from '../../ui/buttons/Button'

 const SidebarNav = ({visible}) => {
  return (
      <nav className={`visible-${visible}`}>
        <ul>
          <li>
            <img src={home} alt='home'/>
            <Link to={`/`}>Inicio</Link>
          </li>
          <li>
            <img src={news} alt='home'/>
            <Link to={`/news`}>Blog</Link>
          </li>
         {/*  <li>
            <img src={contact} alt='home'/>
            <Link to={`/recursos`}>Descargas</Link>
          </li> */}
        </ul>
        <div className='group-social-media'>
          <Button fontSize='15px' borderRadius={'4px'} height='38px' colorbg='#6064fc' text='Acceder' colorText='white' colorBorder='#6064fc' width='170px' href='https://app.ludenseducacion.cl/autenticacion/login'/>
          <Button fontSize='15px' borderRadius={'4px'} height='38px' colorbg='#6064fc' text='Agenda tu demo' colorText='white' colorBorder='#6064fc' width='170px' href='https://2l19u9coi6w.typeform.com/to/rXjzEptx'/>
          <div className='social-media_links'>
            
          <a href='https://www.linkedin.com/company/ludenseducacion/'>
            <span sx={{color:'#A6B0C9 !important'}} >
              <img src={linkedin} alt='home'/>
              Linkedin
            </span>
            </a>
            <a  href='https://www.instagram.com/ludenseducacion/'>
            <span sx={{color:'#A6B0C9 !important'}}>
              <img src={instagram} alt='home'/>
              Instagram
            </span>
            </a>
          </div>  
          <div className='nav_bar-bottom'></div>
        </div>
      </nav>

  )
}

export default SidebarNav