import React from 'react'
import '../ui.css'
export const FooterCard = ({children, image, title, text1, text2, htmlElement }) => {
  return (
    <div className='footer-card__container'>
        <div className='footer-card__info'>
            <h3>{title}</h3>
            <p>{text1}</p>
           {children ?? <p>{text2}</p>} 
           {htmlElement}
        </div>
        <img src={image} alt='decoracion, personas con computadora'/>
    </div>
  )
}
