import React from 'react'
import imagen from '../../img/ludens-logo-gray.svg'

export const Footer = () => {
  return (
    <div className='footer-container'>
      <img src={imagen} alt='logo ludens color gris'/>
    </div>
  )
}
