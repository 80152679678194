import Swal from 'sweetalert2';


export const customAlert = (title, content, type) => {
  Swal.fire({
        title: title,
        text: content,
        icon: type,
        type: type,
        showConfirmButton: (type === 'error' || type === 'warning') ? true : false,
        confirmButtonText: type === 'error' ? 'Ok' : 'Sí',
        showCancelButton: type === 'warning' ? true : false,
        cancelButtonText: 'No',
        confirmButtonColor: '#5E67FF',
        timer: type === 'success' ? 5000 : false
        
      });

}