import React from 'react'

export const Membrete = ({children, title, width='500px', top='100px', color='#2A3342', fontSize}) => {
  return (
    <div className='membrete' style={{width:width, marginTop:top}}>
    <h1 style={{color:color, fontSize}}>{title}</h1>
    {children}
  </div>
    
  )
}
